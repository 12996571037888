.background-primary {
  background-color: #c6010d !important;
}

.background-secondary {
  background-color: #c6010d !important;
}

.background-gray {
  background-color: #f3f3f3 !important;
}

.color-black {
  color: #000000 !important;
}

.color-white {
  color: #ffffff !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

::-webkit-input-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

@font-face {
  font-family: 'Aeonik Air';
  src: url("../fonts/Aeonik-AirItalic.eot");
  src: url("../fonts/Aeonik-AirItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-AirItalic.woff2") format("woff2"), url("../fonts/Aeonik-AirItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-BlackItalic.eot");
  src: url("../fonts/Aeonik-BlackItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-BlackItalic.woff2") format("woff2"), url("../fonts/Aeonik-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik Air';
  src: url("../fonts/Aeonik-Air.eot");
  src: url("../fonts/Aeonik-Air.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Air.woff2") format("woff2"), url("../fonts/Aeonik-Air.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Black.eot");
  src: url("../fonts/Aeonik-Black.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Black.woff2") format("woff2"), url("../fonts/Aeonik-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Bold.eot");
  src: url("../fonts/Aeonik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Bold.woff2") format("woff2"), url("../fonts/Aeonik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Light.eot");
  src: url("../fonts/Aeonik-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Light.woff2") format("woff2"), url("../fonts/Aeonik-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-BoldItalic.eot");
  src: url("../fonts/Aeonik-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-BoldItalic.woff2") format("woff2"), url("../fonts/Aeonik-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-LightItalic.eot");
  src: url("../fonts/Aeonik-LightItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-LightItalic.woff2") format("woff2"), url("../fonts/Aeonik-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Medium.eot");
  src: url("../fonts/Aeonik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Medium.woff2") format("woff2"), url("../fonts/Aeonik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-MediumItalic.eot");
  src: url("../fonts/Aeonik-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-MediumItalic.woff2") format("woff2"), url("../fonts/Aeonik-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Regular.eot");
  src: url("../fonts/Aeonik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Regular.woff2") format("woff2"), url("../fonts/Aeonik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-RegularItalic.eot");
  src: url("../fonts/Aeonik-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-RegularItalic.woff2") format("woff2"), url("../fonts/Aeonik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-Thin.eot");
  src: url("../fonts/Aeonik-Thin.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Thin.woff2") format("woff2"), url("../fonts/Aeonik-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aeonik';
  src: url("../fonts/Aeonik-ThinItalic.eot");
  src: url("../fonts/Aeonik-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-ThinItalic.woff2") format("woff2"), url("../fonts/Aeonik-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@media (min-width: 1681px) {
  .container {
    max-width: 1400px;
  }
}

body,
html {
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Aeonik", sans-serif;
  font-size: 1.125rem;
  color: #000000;
  line-height: 1.875rem;
  font-weight: 400;
}

@media (max-width: 1680px) {
  body {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}

.menu-opened {
  height: 100vh;
  overflow: hidden;
}

.show-xs {
  display: none;
}

@media (max-width: 574px) {
  .show-xs {
    display: block;
  }
}

.hide-xs {
  display: block;
}

@media (max-width: 574px) {
  .hide-xs {
    display: none;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none !important;
  transition: all 0.2s ease;
}

.main-heading,
h1 {
  font-size: 4.375rem;
  color: #ffffff;
  line-height: 5rem;
  font-weight: 300;
}

.main-heading span,
h1 span {
  color: #c6010d;
}

@media (max-width: 1680px) {
  .main-heading,
  h1 {
    font-size: 3.125rem;
    line-height: 4.0625rem;
  }
}

@media (max-width: 1199px) {
  .main-heading,
  h1 {
    font-size: 2.8125rem;
    line-height: 3.4375rem;
  }
}

@media (max-width: 574px) {
  .main-heading,
  h1 {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
}

.sec-heading,
h2 {
  font-size: 2.8rem;
  font-weight: 300;
  color: #000000;
}

.sec-heading span,
h2 span {
  color: #c6010d;
}

@media (max-width: 1680px) {
  .sec-heading,
  h2 {
    font-size: 2.3rem;
  }
}

@media (max-width: 1199px) {
  .sec-heading,
  h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 991px) {
  .sec-heading,
  h2 {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .sec-heading,
  h2 {
    font-size: 1.7rem;
  }
}

h3 {
  font-size: 2.1875rem;
  color: #000000;
  font-weight: 300;
}

@media (max-width: 1680px) {
  h3 {
    font-size: 1.75rem;
  }
}

@media (max-width: 1680px) {
  h3 {
    font-size: 1.5625rem;
  }
}

@media (max-width: 991px) {
  h3 {
    font-size: 1.4rem;
  }
}

.sub-heading {
  font-size: 1.375rem;
  color: #ffffff;
  font-weight: 300;
  margin-top: 1.25rem;
  letter-spacing: 1px;
  line-height: 2.2rem;
  max-width: 35rem;
}

@media (max-width: 1680px) {
  .sub-heading {
    font-size: 1.25rem;
    max-width: 31rem;
  }
}

@media (max-width: 574px) {
  .sub-heading {
    font-size: 1.125rem;
    line-height: 1.9rem;
  }
}

.btn {
  font-size: 0.9375rem;
  color: #ffffff;
  background-color: #c6010d;
  padding: 0.7rem 2.1875rem;
  border-radius: 1.25rem;
  letter-spacing: 1px;
}

.btn:after {
  content: "";
  background-image: url("../images/btn-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 21px;
  height: 18px;
  display: inline-block;
}

.btn:hover {
  color: #ffffff;
  background-color: #0d1215;
}

@media (max-width: 1199px) {
  .btn {
    padding: 0.625rem 1.5rem;
  }
}

.white-btn {
  background-color: #ffffff;
  color: #c6010d;
}

.white-btn:after {
  background-image: url("../images/btn-icon2.svg");
}

.white-btn:hover {
  color: #ffffff;
  background-color: #0d1215;
}

.white-btn:hover:after {
  background-image: url("../images/btn-icon.svg");
}

.link-txt {
  font-size: 1rem;
  color: #000000;
  font-weight: 700;
  display: block;
}

.link-txt:after {
  content: "";
  background-image: url("../images/red-right-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 14px;
  width: 30px;
  margin-left: 1.25rem;
  display: inline-block;
  vertical-align: -3px;
}

.link-txt:hover {
  color: #c6010d;
}

.bk-list ul li {
  color: #ffffff;
  font-weight: 300;
  margin-top: 1.6rem;
  position: relative;
  padding-left: 2.5rem;
}

.bk-list ul li:first-child {
  margin-top: 0;
}

.bk-list ul li:before {
  content: "";
  background-image: url("../images/tick.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 10px;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
  top: 5px;
  border: 1px solid #ffffff;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .bk-list ul li {
    margin-top: 1.2rem;
  }
}

.bk-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: #ffffff;
  transition: all 0.2s ease;
}

.bk-header .navbar-brand {
  padding: 0;
  transition: all 0.2 ease;
}

.bk-header .navbar {
  padding: 0;
}

.bk-header .navbar-nav ul {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.bk-header .navbar-nav ul li {
  padding: 2rem 1.875rem;
  position: relative;
  transition: all .2s ease;
}

.bk-header .navbar-nav ul li:first-child {
  padding-left: 0;
}

.bk-header .navbar-nav ul li:last-child {
  padding-right: 0;
}

.bk-header .navbar-nav ul li a {
  font-size: 1rem;
  color: transparent;
  text-transform: capitalize;
  display: inline-block;
  text-align: center;
  position: relative;
  padding: 0px 4px;
}

.bk-header .navbar-nav ul li a:after {
  content: attr(title);
  position: absolute;
  color: #000000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-weight: 400;
  transition: all .2s ease;
}

@includ mq(mxl) {
  .bk-header .navbar-nav ul li a {
    font-size: 0.875rem;
  }
}

.bk-header .navbar-nav ul li:hover a:after, .bk-header .navbar-nav ul li.active a:after {
  font-weight: 700;
  color: #c6010d;
}

@media (min-width: 992px) {
  .bk-header .navbar-nav ul li:hover .sub-menu {
    display: block;
  }
}

.bk-header .navbar-nav ul li .sub-menu {
  position: absolute;
  left: 0;
  top: 100%;
  flex-wrap: wrap;
  background-color: #c6010d;
  padding: 1.5rem 0rem;
  width: 18rem;
  display: none;
  transition: all .2s ease;
  animation: fadein .6s ease;
}

.bk-header .navbar-nav ul li .sub-menu li {
  width: 100%;
  padding: 0;
}

.bk-header .navbar-nav ul li .sub-menu li:hover a {
  font-weight: 400;
  background-color: #ffffff;
  color: #c6010d;
}

.bk-header .navbar-nav ul li .sub-menu li a {
  text-align: left;
  padding: .4rem 2rem;
  width: 100%;
  font-weight: 400;
  color: #ffffff;
}

.bk-header .navbar-nav ul li .sub-menu li:first-child {
  padding-top: 0rem;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1680px) {
  .bk-header .navbar-nav ul li {
    padding: 2rem  1.2rem;
  }
  .bk-header .navbar-nav ul li .sub-menu {
    width: 15rem;
  }
  .bk-header .navbar-nav ul li .sub-menu li a {
    padding: .4rem 1.5rem;
  }
}

@media (max-width: 1199px) {
  .bk-header .navbar-nav ul li {
    padding: 1.5rem 1rem;
  }
  .bk-header .navbar-nav ul li:hover a {
    font-weight: 400;
  }
}

@media (max-width: 991px) {
  .bk-header .navbar-nav {
    align-items: flex-start;
  }
  .bk-header .navbar-nav ul {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: inherit;
    width: 100%;
  }
  .bk-header .navbar-nav ul li {
    width: 100%;
  }
  .bk-header .navbar-nav ul li a:after {
    color: #ffffff;
  }
  .bk-header .navbar-nav ul li:hover a:after, .bk-header .navbar-nav ul li.active a:after {
    color: #ffffff;
  }
  .bk-header .navbar-nav ul li.menu-item-has-children {
    position: relative;
  }
  .bk-header .navbar-nav ul li.menu-item-has-children:after {
    content: "";
    position: absolute;
    right: 0;
    top: 24px;
    width: 16px;
    height: 8px;
    background-image: url("../images/dropdown-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .2s ease;
  }
  .bk-header .navbar-nav ul li.menu-item-has-children.active:after {
    transform: rotate(180deg);
  }
  .bk-header .navbar-nav ul li.menu-item-has-children .sub-menu {
    position: relative;
    top: inherit;
    left: inherit;
    padding: .6rem 0rem;
    transition: none;
  }
  .bk-header .navbar-nav ul li.menu-item-has-children .sub-menu li a {
    padding: .4rem 0rem;
    color: #ffffff;
  }
  .bk-header .navbar-nav ul li.menu-item-has-children .sub-menu li a:hover {
    background-color: transparent;
  }
  .bk-header .navbar-nav ul li {
    padding: 15px 0;
  }
}

@media (max-width: 1199px) {
  .bk-header .navbar-brand {
    width: 11.25rem;
  }
}

@media (max-width: 991px) {
  .bk-header {
    padding: 0.5rem 0;
  }
  .bk-header .navbar-brand {
    order: 1;
  }
  .bk-header .navbar-collapse {
    position: fixed;
    top: 82px;
    left: 0;
    padding: 1.875rem;
    width: 18.75rem;
    height: 100%;
    overflow: scroll;
    background-color: #c6010d;
    overflow: -moz-scrollbars-none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }
  .bk-header .navbar-collapse::-webkit-scrollbar {
    width: 0 !important;
  }
  .bk-header .navbar-collapse.collapsing {
    left: -18.75rem;
    transition: height 0s ease;
  }
  .bk-header .navbar-collapse.show {
    left: 0;
    transition: left 0.3s linear;
  }
  .bk-header .navbar-toggler.collapsed ~ .navbar-collapse {
    transition: left 0.3s linear;
  }
  .bk-header .navbar-toggler {
    padding: 0;
    outline: 0;
    border: none;
    order: 3;
    height: 3rem;
    width: 3rem;
  }
  .bk-header .navbar-toggler-icon {
    background-image: none !important;
    position: relative;
    height: 3px;
    width: 30px;
    background-color: transparent;
    display: block;
    margin-left: auto;
  }
  .bk-header .navbar-toggler-icon:after, .bk-header .navbar-toggler-icon:before {
    content: "";
    position: absolute;
    background-color: #c6010d;
    height: 3px;
    width: 30px;
    left: 0;
    transition: all 0.2s ease-out;
  }
  .bk-header .navbar-toggler-icon:before {
    transform: rotate(45deg);
    top: 0;
  }
  .bk-header .navbar-toggler-icon:after {
    transform: rotate(-45deg);
    top: 0;
  }
  .bk-header .navbar-toggler.collapsed .navbar-toggler-icon {
    background-color: #c6010d;
  }
  .bk-header .navbar-toggler.collapsed .navbar-toggler-icon:before {
    transform: rotate(0deg);
    top: -10px !important;
  }
  .bk-header .navbar-toggler.collapsed .navbar-toggler-icon:after {
    transform: rotate(0deg);
    bottom: -10px !important;
    top: inherit;
  }
}

.bk-header .get-start {
  padding-left: 3.75rem;
}

@media (max-width: 1680px) {
  .bk-header .get-start {
    padding-left: 2.5rem;
  }
}

@media (max-width: 991px) {
  .bk-header .get-start {
    order: 2;
    margin-left: auto;
    padding-right: 2rem;
    padding-left: 0;
  }
}

@media (max-width: 574px) {
  .bk-header .get-start {
    padding-left: 0;
    margin-top: 1.25rem;
    margin-left: 0;
  }
  .bk-header .get-start .btn {
    background-color: #ffffff;
    color: #c6010d;
    box-shadow: none;
    outline: 0;
  }
  .bk-header .get-start .btn:after {
    background-image: url("../images/btn-icon2.png");
  }
}

.bk-header.fixedtop {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15);
}

.bk-header.fixedtop .navbar-brand {
  transform: scale(0.8);
  transform-origin: center;
}

.bk-header.fixedtop .navbar-nav ul li {
  padding: 1.5rem 1.875rem;
}

.bk-header.fixedtop .navbar-nav ul li:first-child {
  padding-left: 0;
}

.bk-header.fixedtop .navbar-nav ul li:last-child {
  padding-right: 0;
}

@media (max-width: 1680px) {
  .bk-header.fixedtop .navbar-nav ul li {
    padding: 1.6rem 1.2rem;
  }
}

@media (max-width: 1199px) {
  .bk-header.fixedtop .navbar-brand {
    transform: scale(0.9);
  }
  .bk-header.fixedtop .get-start .btn {
    padding: 0.5rem 1.5rem;
  }
  .bk-header.fixedtop .navbar-nav ul li {
    padding: 1.5rem 1rem;
  }
}

@media (max-width: 991px) {
  .bk-header.fixedtop {
    padding: 0.5rem 0;
  }
  .bk-header.fixedtop .navbar-brand {
    transform: scale(1);
  }
  .bk-header.fixedtop .navbar-nav ul li {
    padding: 15px 0px;
  }
  .bk-header.fixedtop .get-start .btn {
    padding: 0.625rem 1.5rem;
  }
}

.menu-overlay {
  display: none;
}

.menu-opened .menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 120vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  display: block;
}

body.modal-open {
  padding-right: 0px !important;
}

.get-start-popup .modal-dialog {
  max-width: 35rem;
}

.get-start-popup .modal-dialog .modal-content {
  padding: 3rem;
  border: none;
}

.get-start-popup .modal-dialog .modal-content .modal-header {
  padding: 0;
  border: none;
  position: relative;
}

.get-start-popup .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 2rem;
  font-weight: 500;
}

.get-start-popup .modal-dialog .modal-content .modal-header .close {
  position: absolute;
  right: 12px;
  top: 27px;
  outline: 0;
  border: none;
  opacity: 1;
  padding: 0;
}

.get-start-popup .modal-dialog .modal-content .modal-header .close span {
  width: 35px;
  height: 35px;
  border: 1px solid #000000;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.get-start-popup .modal-dialog .modal-content .modal-header .close span:after, .get-start-popup .modal-dialog .modal-content .modal-header .close span:before {
  content: "";
  width: 20px;
  height: 1px;
  background-color: #000000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.get-start-popup .modal-dialog .modal-content .modal-header .close span:after {
  transform: rotate(45deg);
}

.get-start-popup .modal-dialog .modal-content .modal-header .close span:before {
  transform: rotate(-45deg);
}

.get-start-popup .modal-dialog .modal-content .modal-body {
  padding: 0;
}

.get-start-popup .input-feild .btn {
  margin-top: .5rem;
}

@media (max-width: 767px) {
  .get-start-popup .modal-dialog {
    max-width: 28rem;
  }
  .get-start-popup .modal-dialog .modal-content {
    padding: 2rem;
  }
}

@media (max-width: 574px) {
  .get-start-popup .modal-dialog .modal-content {
    padding: 2rem 1.5rem;
  }
  .get-start-popup .modal-dialog .modal-content .modal-header .modal-title {
    font-size: 1.5rem;
  }
  .get-start-popup .modal-dialog .modal-content .modal-header .close {
    top: 14px;
  }
}

.bk-footer {
  background-color: #c6010d;
  padding: 6.25rem 0rem;
}

@media (max-width: 1680px) {
  .bk-footer {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-footer {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-footer {
    padding: 2.5rem 0rem;
  }
}

.bk-footer .footer-top ul {
  display: flex;
  align-items: center;
}

.bk-footer .footer-top ul li {
  padding-left: 2.125rem;
}

.bk-footer .footer-top ul li a {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 400;
}

.bk-footer .footer-top ul li a:hover {
  color: #000000;
}

.bk-footer .footer-top ul li:first-child {
  padding-left: 0;
}

.bk-footer .footer-top address {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 400;
  line-height: 1.625rem;
}

.bk-footer .footer-top .stay-touch {
  text-align: center;
}

.bk-footer .footer-top .stay-touch form {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 1.5rem;
  width: 100%;
  height: 4.5rem;
  padding: 1rem 2rem;
}

.bk-footer .footer-top .stay-touch form input[type="email"] {
  font-size: 1.2rem;
  color: #ffffff;
  font-weight: 300;
  background-color: transparent;
  border: none;
  outline: 0;
  width: calc(100% - 2rem);
}

.bk-footer .footer-top .stay-touch form input[type="email"]::placeholder {
  color: #ffffff;
}

.bk-footer .footer-top .stay-touch form input[type="submit"] {
  width: 2rem;
  color: transparent;
  border: none;
  outline: 0;
  background: url("../images/right-arrow.svg") no-repeat center/30px transparent;
}

.bk-footer .footer-top .stay-touch .stay-touch-title {
  font-size: 2.8125rem;
  color: #ffffff;
  font-weight: 300;
}

@media (max-width: 1680px) {
  .bk-footer .footer-top ul li {
    padding-left: 1.875rem;
  }
  .bk-footer .footer-top .stay-touch .stay-touch-title {
    font-size: 1.875rem;
  }
  .bk-footer .footer-top .stay-touch form {
    height: 4.2rem;
  }
}

@media (max-width: 1199px) {
  .bk-footer .footer-top ul li {
    padding-left: 1.4375rem;
  }
  .bk-footer .footer-top ul li a {
    font-size: 0.875rem;
  }
  .bk-footer .footer-top address {
    font-size: 0.875rem;
    line-height: 1.5625rem;
  }
  .bk-footer .footer-top .stay-touch .stay-touch-title {
    font-size: 1.5625rem;
  }
  .bk-footer .footer-top .stay-touch form {
    height: 4rem;
    padding: 1rem 1.5rem;
    margin-top: 1.5rem;
  }
  .bk-footer .footer-top .stay-touch form input[type="email"] {
    font-size: 1rem;
  }
  .bk-footer .footer-top .stay-touch form input[type="submit"] {
    background-size: 26px;
  }
}

@media (max-width: 991px) {
  .bk-footer .footer-top div[class*="col"] {
    margin-top: 1.875rem;
  }
  .bk-footer .footer-top div[class*="col"]:first-child {
    margin-top: 0;
  }
  .bk-footer .footer-top .stay-touch {
    text-align: left;
  }
}

@media (max-width: 767px) {
  .bk-footer .footer-top .stay-touch form {
    height: 3.5rem;
  }
}

@media (max-width: 574px) {
  .bk-footer .footer-top ul {
    flex-direction: column;
    text-align: center;
  }
  .bk-footer .footer-top ul li {
    padding-left: 0;
    padding-top: 0.9375rem;
  }
  .bk-footer .footer-top ul li:first-child {
    padding-top: 0;
  }
  .bk-footer .footer-top ul li a {
    font-size: 1rem;
  }
  .bk-footer .footer-top address {
    text-align: center;
  }
  .bk-footer .footer-top .stay-touch {
    text-align: center;
  }
}

.bk-footer .footer-bttom {
  margin-top: 5rem;
  padding-top: 3rem;
  border-top: 1px solid #ffffff;
}

.bk-footer .footer-bttom p {
  color: #ffffff;
  font-weight: 300;
  font-size: 1rem;
}

.bk-footer .footer-bttom p img {
  height: 1.2rem;
}

.bk-footer .footer-bttom ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bk-footer .footer-bttom ul li {
  padding-right: 1.2rem;
}

.bk-footer .footer-bttom ul li a:hover svg path {
  fill: #000000;
}

.bk-footer .footer-bttom ul li:last-child {
  padding-right: 0;
}

@media (max-width: 1199px) {
  .bk-footer .footer-bttom {
    margin-top: 4rem;
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .bk-footer .footer-bttom p {
    text-align: center;
    margin-top: .8rem;
  }
  .bk-footer .footer-bttom ul {
    justify-content: center;
  }
}

.bk-banner {
  height: 100%;
  background-image: url("../images/banner-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 6.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.bk-banner .down-arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  animation: bounce 2s infinite;
  bottom: 1.25rem;
}

.bk-banner .banner-inner {
  max-width: 50rem;
}

.bk-banner .banner-inner .btn {
  margin-top: 2.5rem;
}

@media (max-width: 1680px) {
  .bk-banner .banner-inner .btn {
    margin-top: 2rem;
  }
}

@media (max-width: 991px) {
  .bk-banner {
    padding: 3.125rem 0;
  }
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.connect-us {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.connect-us:after {
  content: "";
  width: 40px;
  height: 1px;
  background-color: #c6010d;
  bottom: 0;
  top: 0;
  margin: auto;
  right: 8px;
  position: absolute;
  transition: all 0.3s ease;
  transition-delay: 0.2s;
}

.connect-us ul li:last-child a:after {
  display: none;
}

.connect-us a {
  display: flex;
  align-items: center;
  height: 3.75rem;
  width: 20rem;
  background-color: #ffffff;
  padding: 0 10px;
  font-size: 1.125rem;
  color: #000000;
  font-weight: 500;
  position: relative;
  right: -16.25rem;
  transition: all 0.3s ease;
}

.connect-us a figure {
  height: 3.125rem;
  width: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.connect-us a span {
  padding-left: 5px;
  width: calc(100% - 50px);
}

.connect-us a:hover {
  right: 0;
}

@media (max-width: 767px) {
  .connect-us a {
    font-size: 1rem;
    width: 17.1875rem;
    right: -13.25rem;
  }
}

@media (max-width: 767px) {
  .connect-us {
    top: inherit;
    transform: inherit;
    bottom: 1.875rem;
  }
}

.bk-why-choose-us {
  padding: 6.25rem 0rem;
}

.bk-why-choose-us .content-block {
  max-width: 50rem;
  text-align: center;
  margin: auto;
}

.bk-why-choose-us .content-block p {
  text-align: justify;
  text-align-last: center;
}

@media (max-width: 1680px) {
  .bk-why-choose-us {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-why-choose-us {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-why-choose-us {
    padding: 2.5rem 0rem;
  }
}

.content-block p {
  margin-top: 2.1875rem;
}

.content-block .btn {
  margin-top: 2.5rem;
}

@media (max-width: 1680px) {
  .content-block p {
    margin-top: 1.5rem;
  }
  .content-block .btn {
    margin-top: 2rem;
  }
}

@media (max-width: 1199px) {
  .content-block .btn {
    margin-top: 1.875rem;
  }
}

@media (max-width: 767px) {
  .content-block p {
    margin-top: 1rem;
  }
}

.bk-services {
  background-image: url("../images/service-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.bk-services .sec-heading,
.bk-services h2 {
  color: #ffffff;
  opacity: 0;
  transition: all 0.2s ease;
}

.bk-services .our-services-list {
  height: 56.25rem;
  background-color: rgba(0, 0, 0, 0.4);
  border-right: 1px solid #ffffff;
  display: flex;
  flex-direction: column;
  padding: 6.25rem 3.125rem 5rem;
  transition: all 0.2s ease;
}

.bk-services .our-services-list:last-child {
  border: none;
}

.bk-services .our-services-list .our-services-cnt {
  margin-top: auto;
}

.bk-services .our-services-list .our-services-cnt h3 {
  color: #ffffff;
}

.bk-services .our-services-list .our-services-cnt p {
  color: #ffffff;
  display: none;
  padding-top: .7rem;
}

.bk-services .our-services-list .our-services-cnt a {
  font-size: 1rem;
  color: #ffffff;
  font-weight: 700;
  margin-top: 1.5625rem;
  display: block;
}

.bk-services .our-services-list .our-services-cnt a:after {
  content: "";
  background-image: url("../images/right-arrow.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 14px;
  width: 30px;
  margin-left: 1.5625rem;
  display: inline-block;
}

.bk-services .our-services-list .our-services-cnt a:hover {
  color: #c6010d;
}

.bk-services .our-services-list .our-services-cnt a:hover:after {
  filter: invert(72%) sepia(71%) saturate(7242%) hue-rotate(349deg) brightness(74%) contrast(115%);
}

.bk-services .our-services-list:hover {
  background-color: rgba(198, 1, 13, 0.4);
}

.bk-services .our-services-list:hover .sec-heading,
.bk-services .our-services-list:hover h2 {
  opacity: 1;
}

@media (max-width: 1680px) {
  .bk-services .our-services-list:hover .our-services-cnt p {
    padding-top: 1.25rem;
  }
}

@media (max-width: 991px) {
  .bk-services .our-services-list:hover {
    background-color: transparent;
  }
}

@media (max-width: 1680px) {
  .bk-services .our-services-list {
    padding: 4rem 1rem 3rem;
    height: 37.5rem;
  }
  .bk-services .our-services-list .sec-heading,
  .bk-services .our-services-list h2 {
    font-size: 2.25rem;
  }
  .bk-services .our-services-list .our-services-cnt h3 {
    font-size: 1.75rem;
  }
}

@media (max-width: 1199px) {
  .bk-services .our-services-list .sec-heading,
  .bk-services .our-services-list h2 {
    font-size: 1.875rem;
  }
  .bk-services .our-services-list .our-services-cnt h3 {
    font-size: 1.375rem;
  }
  .bk-services .our-services-list .our-services-cnt a:after {
    margin-left: 1.25rem;
  }
}

@media (max-width: 991px) {
  .bk-services .our-services-list {
    height: auto;
    background-color: transparent;
    border: none;
    padding: 0 15px;
    margin-top: 3.125rem;
  }
  .bk-services .our-services-list .sec-heading,
  .bk-services .our-services-list h2 {
    display: none;
  }
  .bk-services .our-services-list .our-services-cnt {
    margin-top: 0;
    height: 100%;
    position: relative;
    padding-bottom: 2.5rem;
  }
  .bk-services .our-services-list .our-services-cnt h3 {
    font-size: 1.5625rem;
  }
  .bk-services .our-services-list .our-services-cnt p {
    display: block !important;
    padding-top: 1.25rem;
  }
  .bk-services .our-services-list .our-services-cnt a {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}

@media (max-width: 767px) {
  .bk-services .our-services-list .our-services-cnt p {
    padding-top: 0.9375rem;
  }
  .bk-services .our-services-list .our-services-cnt a {
    margin-top: 0.9375rem;
  }
}

@media (max-width: 574px) {
  .bk-services .our-services-list {
    margin-top: 2.5rem;
  }
}

@media (max-width: 991px) {
  .bk-services {
    padding-bottom: 3.125rem;
  }
  .bk-services:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .bk-services .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.bk-services .owl-carousel.mob-services-slider {
  display: flex;
}

@media (max-width: 767px) {
  .bk-services .owl-carousel.mob-services-slider {
    display: block;
  }
}

.bk-solution {
  padding: 6.25rem 0rem;
}

.bk-solution h2 {
  text-align: center;
}

.bk-solution .solution-cnt {
  margin-top: 4.375rem;
}

@media (min-width: 1681px) {
  .bk-solution .solution-cnt .col-md-4 {
    padding: 0 30px;
  }
}

.bk-solution .solution-cnt p {
  margin-top: 1.875rem;
}

.bk-solution .solution-cnt .link-txt {
  margin-top: 1.5625rem;
}

@media (max-width: 1680px) {
  .bk-solution .solution-cnt {
    margin-top: 3.125rem;
  }
}

@media (max-width: 1199px) {
  .bk-solution .solution-cnt p {
    margin-top: 1.25rem;
  }
}

@media (max-width: 767px) {
  .bk-solution .solution-cnt {
    margin-top: 0;
  }
  .bk-solution .solution-cnt .col-md-4 {
    margin-top: 2.5rem;
  }
  .bk-solution .solution-cnt p {
    margin-top: 0.9375rem;
  }
}

@media (max-width: 574px) {
  .bk-solution .solution-cnt .col-md-4 {
    margin-top: 1.875rem;
  }
}

.bk-solution .owl-carousel.solution-cnt {
  display: flex;
}

@media (max-width: 767px) {
  .bk-solution .owl-carousel.solution-cnt {
    display: block;
  }
}

@media (max-width: 1680px) {
  .bk-solution {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-solution {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-solution {
    padding: 2.5rem 0rem;
  }
}

.bk-meet-us {
  padding: 6.25rem 0rem;
  padding-top: 0 !important;
}

.bk-meet-us .left {
  background-color: #c6010d;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 5rem;
}

.bk-meet-us .left h2 {
  font-size: 2.5rem;
  color: #ffffff;
}

.bk-meet-us .left p {
  color: #ffffff;
  margin-top: 0.9375rem;
}

.bk-meet-us .left .btn {
  margin-top: 2.5rem;
  background-color: #ffffff;
  color: #c6010d;
}

.bk-meet-us .left .btn:after {
  background-image: url("../images/btn-icon2.svg");
}

.bk-meet-us .left .btn:hover {
  color: #ffffff;
  background-color: #0d1215;
}

.bk-meet-us .left .btn:hover:after {
  background-image: url("../images/btn-icon.svg");
}

.bk-meet-us .right figure {
  height: 100%;
}

.bk-meet-us .right figure img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

@media (max-width: 1680px) {
  .bk-meet-us .left {
    padding: 1.875rem;
  }
  .bk-meet-us .left h2 {
    font-size: 2.25rem;
  }
}

@media (max-width: 991px) {
  .bk-meet-us .left h2 {
    font-size: 2rem;
  }
}

@media (max-width: 767px) {
  .bk-meet-us .right figure {
    height: auto;
  }
  .bk-meet-us .right figure img {
    height: auto;
  }
}

@media (max-width: 574px) {
  .bk-meet-us .left {
    padding: 1.5625rem;
  }
  .bk-meet-us .left h2 {
    font-size: 1.75rem;
  }
  .bk-meet-us .left .btn {
    margin-top: 1.5625rem;
  }
}

@media (max-width: 1680px) {
  .bk-meet-us {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-meet-us {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-meet-us {
    padding: 2.5rem 0rem;
  }
}

.bk-testimonial {
  background-image: url("../images/testimonial-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10rem 0;
}

.bk-testimonial .testimonial-slider {
  max-width: 40.625rem;
}

.bk-testimonial .testimonial-slider p {
  color: #ffffff;
  font-size: 1.25rem;
  line-height: 2.125rem;
}

.bk-testimonial .testimonial-slider .testimonial-footer {
  font-size: 1.5625rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: 1.5625rem;
}

.bk-testimonial .testimonial-slider .testimonial-cnt {
  padding-top: 6.25rem;
}

.bk-testimonial .testimonial-slider .testimonial-cnt:before {
  content: "";
  background-image: url("../images/quotes.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 3.125rem;
  height: 2.5625rem;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 1680px) {
  .bk-testimonial {
    padding: 6rem 0;
  }
  .bk-testimonial .testimonial-slider .testimonial-footer {
    font-size: 1.375rem;
    margin-top: 1.125rem;
  }
  .bk-testimonial .testimonial-slider .testimonial-cnt {
    padding-top: 4.6875rem;
  }
  .bk-testimonial .testimonial-slider p {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
}

@media (max-width: 1199px) {
  .bk-testimonial {
    padding: 5rem 0;
  }
  .bk-testimonial .testimonial-slider p {
    font-size: 1rem;
  }
}

@media (max-width: 991px) {
  .bk-testimonial {
    padding: 3rem 0;
  }
  .bk-testimonial .testimonial-slider .testimonial-cnt:before {
    width: 2.5rem;
    height: 2rem;
  }
  .bk-testimonial .testimonial-slider .testimonial-footer {
    font-size: 1.25rem;
  }
}

@media (max-width: 574px) {
  .bk-testimonial .testimonial-slider .testimonial-cnt {
    padding-top: 3.4375rem;
  }
  .bk-testimonial .testimonial-slider .testimonial-cnt p {
    font-size: 1rem;
  }
}

.bk-logos {
  padding: 6.25rem 0rem;
}

@media (max-width: 1680px) {
  .bk-logos {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-logos {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-logos {
    padding: 2.5rem 0rem;
  }
}

.bk-logos .logo-slider .owl-stage {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.bk-logos .logo-slider figure {
  text-align: center;
}

.bk-logos .logo-slider img {
  display: inline-block;
  width: auto;
  max-width: 150px;
  max-height: 120px;
  opacity: 0.4;
  transition: all 0.2s ease;
}

.bk-logos .logo-slider img:hover {
  opacity: 1;
}

@media (max-width: 1680px) {
  .bk-logos .logo-slider img {
    max-width: 120px;
    max-height: 80px;
  }
}

.bk-about-banner {
  min-height: auto !important;
  padding: 7rem 0rem;
  height: auto;
}

@media (max-width: 1199px) {
  .bk-about-banner {
    padding: 5rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-about-banner {
    padding: 3rem 0rem;
  }
}

@media (max-width: 767px) {
  .bk-about-banner {
    padding: 3rem 0rem 5rem;
  }
}

.bk-our-services .right {
  background-color: #c6010d;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bk-our-services .right h4 {
  font-size: 1.55rem;
  color: #ffffff;
  font-weight: 300;
  margin-bottom: 3rem;
  line-height: 2rem;
}

@media (max-width: 1680px) {
  .bk-our-services .right h4 {
    font-size: 1.4rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: 991px) {
  .bk-our-services .right {
    margin-top: 2rem;
    padding: 2rem;
    height: auto;
  }
}

@media (max-width: 574px) {
  .bk-our-services .right {
    padding: 1.5rem 1.2rem;
  }
}

.bk-offering-services {
  padding: 6rem 0;
}

.bk-offering-services .content-block {
  padding-left: 2rem;
}

@media (max-width: 1680px) {
  .bk-offering-services {
    padding: 4rem 0rem;
  }
  .bk-offering-services .content-block {
    padding-left: 1rem;
  }
}

@media (max-width: 767px) {
  .bk-offering-services {
    padding: 3rem 0rem;
  }
  .bk-offering-services .content-block {
    padding-left: 0;
    margin-top: 2rem;
  }
}

.services-slider-inner {
  background-color: #000000;
  transform: all 0.2s ease;
}

.services-slider-inner:hover {
  background-color: #c6010d;
}

.services-slider-inner img {
  height: 34rem;
  object-fit: cover;
  object-position: center;
  opacity: 0.4;
}

.services-slider-inner .figure-caption {
  position: absolute;
  left: 3rem;
  bottom: 3rem;
  z-index: 2;
}

.services-slider-inner .figure-caption h3 {
  color: #ffffff;
  font-size: 1.6rem;
  position: relative;
  padding-left: 2.5rem;
}

.services-slider-inner .figure-caption h3:before {
  content: "";
  background-image: url("../images/tick.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 13px;
  height: 24px;
  width: 24px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
}

@media (max-width: 1199px) {
  .services-slider-inner .figure-caption {
    left: 2rem;
  }
  .services-slider-inner .figure-caption h3 {
    font-size: 1.3rem;
  }
  .services-slider-inner .figure-caption h3:before {
    top: 2px;
  }
}

@media (max-width: 767px) {
  .services-slider-inner img {
    height: 27rem;
  }
}

@media (max-width: 574px) {
  .services-slider-inner img {
    height: 25rem;
  }
}

.services-slider .owl-nav button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  outline: 0;
}

.services-slider .owl-nav .owl-prev {
  left: 1.2rem;
}

.services-slider .owl-nav .owl-next {
  right: 1.2rem;
}

.bk-team-work {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0rem;
}

@media (max-width: 1680px) {
  .bk-team-work {
    padding: 5rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-team-work {
    padding: 4rem 0rem;
  }
}

@media (max-width: 574px) {
  .bk-team-work {
    padding: 3rem 0rem;
  }
}

.team-work-inner {
  max-width: 50rem;
  margin: auto;
  text-align: center;
}

.team-work-inner h2 {
  font-size: 2rem;
  color: #ffffff;
}

.team-work-inner p {
  color: #ffffff;
  margin-top: 2rem;
}

.team-work-inner .btn {
  margin-top: 2rem;
}

@media (max-width: 991px) {
  .team-work-inner h2 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .team-work-inner p {
    margin-top: 1.2rem;
  }
}

.bk-date-store {
  padding: 6.25rem 0rem;
}

@media (min-width: 1200px) {
  .bk-date-store .row {
    margin: 0px -30px;
  }
  .bk-date-store .row div[class*="col"] {
    padding: 0px 30px;
  }
}

@media (max-width: 1680px) {
  .bk-date-store {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-date-store {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-date-store {
    padding: 2.5rem 0rem;
  }
}

.bk-data-recovery {
  padding: 6.25rem 0rem;
}

@media (max-width: 1680px) {
  .bk-data-recovery {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-data-recovery {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-data-recovery {
    padding: 2.5rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-protect-data .content-block {
    margin-top: 2rem;
  }
}

.bk-contact-banner {
  height: 30rem;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
}

@media (max-width: 1680px) {
  .bk-contact-banner {
    height: 22rem;
  }
}

@media (max-width: 1199px) {
  .bk-contact-banner {
    height: 16rem;
  }
}

@media (max-width: 767px) {
  .bk-contact-banner {
    height: 15rem;
  }
  .bk-contact-banner .connect-us {
    top: 50%;
    transform: translateY(-50%);
    bottom: inherit;
  }
}

.bk-contact {
  padding: 6.25rem 0rem;
}

@media (max-width: 1680px) {
  .bk-contact {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .bk-contact {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .bk-contact {
    padding: 2.5rem 0rem;
  }
}

@media (min-width: 1681px) {
  .bk-contact .row {
    margin: 0 -30px;
  }
  .bk-contact .row div[class*="col"] {
    padding: 0 30px;
  }
}

@media (min-width: 768px) {
  .bk-contact div[class*="col"]:nth-child(-n + 2) .input-feild {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .bk-contact div[class*="col"]:first-child .input-feild {
    margin-top: 0;
  }
}

.input-feild {
  margin-top: 1.5rem;
}

.input-feild label {
  display: block;
  color: #000000;
  margin-bottom: 0.6rem;
}

.input-feild input,
.input-feild textarea {
  height: 3rem;
  width: 100%;
  border: 1px solid #a1a1a1;
  padding: 0 1.2rem;
  color: #333333;
  outline: 0;
}

.input-feild textarea {
  padding: 1.2rem;
  height: 10rem;
  resize: none;
}

.input-feild input::placeholder,
.input-feild textarea::placeholder {
  color: rgba(51, 51, 51, 0.3);
}

.input-feild .btn {
  font-family: -apple-system, BlinkMacSystemFont, "Aeonik", sans-serif;
  color: #ffffff;
  display: inline-block;
  width: auto;
  padding: 0.7rem 2.8rem 0.7rem 2rem;
  height: auto;
  outline: 0;
  border: none;
  background: url("../images/btn-icon.svg") no-repeat 81% 46%/18px #c6010d;
}

.input-feild .btn:hover {
  background-color: #0d1215;
}

@media (max-width: 991px) {
  .input-feild input {
    height: 2.8rem;
  }
}

.bk-map {
  background-color: #c6010d;
}

.bk-map .container {
  position: relative;
}

.bk-map address {
  position: absolute;
  bottom: 4rem;
  left: 5rem;
  font-size: 1.3rem;
  color: #ffffff;
  font-weight: 300;
}

.bk-map figure {
  overflow: hidden;
}

.bk-map figure img {
  transform: scale(1.2);
  object-position: center;
  object-fit: cover;
  height: 30rem;
}

@media (max-width: 1199px) {
  .bk-map figure img {
    height: 27rem;
  }
}

@media (max-width: 991px) {
  .bk-map address {
    font-size: 1.1rem;
  }
  .bk-map figure img {
    height: 22rem;
    object-position: 70%;
  }
}

@media (max-width: 767px) {
  .bk-map figure img {
    object-position: 75%;
  }
}

@media (max-width: 574px) {
  .bk-map address {
    bottom: 2rem;
    left: 1rem;
  }
}

.IT-support-team {
  padding: 6.25rem 0rem;
}

@media (max-width: 1680px) {
  .IT-support-team {
    padding: 3.75rem 0rem;
  }
}

@media (max-width: 1199px) {
  .IT-support-team {
    padding: 3.125rem 0rem;
  }
}

@media (max-width: 991px) {
  .IT-support-team {
    padding: 2.5rem 0rem;
  }
}

.IT-support-team .team-work-inner {
  max-width: 100%;
  padding: 0 4rem;
  text-align: left;
}

@media (min-width: 1200px) {
  .IT-support-team .team-work-inner .row {
    margin: 0 -30px;
  }
  .IT-support-team .team-work-inner .row div[class*="col"] {
    padding: 0 30px;
  }
}

@media (max-width: 1199px) {
  .IT-support-team .team-work-inner {
    padding: 0rem 2.5rem;
  }
}

@media (max-width: 574px) {
  .IT-support-team .team-work-inner {
    padding: 0rem 1rem;
  }
}
