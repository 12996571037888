.bk-solution {
  h2 {
    text-align: center;
  }

  .solution-cnt {
    @include margin-top(70px);
    @include mq(w6) {
      .col-md-4 {
        padding: 0 30px;
      }
    }

    p {
      @include margin-top(30px);
    }

    .link-txt {
      @include margin-top(25px);
    }
    @include mq(mw6) {
      @include margin-top(50px);
    }
    @include mq(mxl) {
      p {
        @include margin-top(20px);
      }
    }
    @include mq(mmd) {
      margin-top: 0;

      .col-md-4 {
        @include margin-top(40px);
      }

      p {
        @include margin-top(15px);
      }
    }
    @include mq(msm) {
      .col-md-4 {
        @include margin-top(30px);
      }
    }
  }
  .owl-carousel.solution-cnt{
    display: flex;

    @include mq(mmd){
      display: block;
    }
  }

  @include cmmn_spacing;
}
