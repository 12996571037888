li {
  list-style: none;
}

a {
  text-decoration: none !important;
  transition: all 0.2s ease;
}

.main-heading,
h1 {
  @include fontSize(70px);
  color: $secondary_font_color;
  @include line-height(80px);
  font-weight: 300;

  span {
    color: $primary_color;
  }
  @include mq(mw6) {
    @include fontSize(50px);
    @include line-height(65px);
  }
  @include mq(mxl) {
    @include fontSize(45px);
    @include line-height(55px);
  }
  @include mq(msm) {
    @include fontSize(35px);
    @include line-height(45px);
  }
}

.sec-heading,
h2 {
  font-size: 2.8rem;
  font-weight: 300;
  color: $primary_font_color;

  span {
    color: $primary_color;
  }

  @include mq(mw6) {
    font-size: 2.3rem;
  }

  @include mq(mxl) {
    font-size: 2.2rem;
  }

  @include mq(mlg) {
    font-size: 2rem;
  }

  @include mq(mmd) {
    font-size: 1.7rem;
  }
}

h3 {
  @include fontSize(35px);
  color: $primary_font_color;
  font-weight: 300;
  @include mq(mw6) {
    @include fontSize(28px);
  }
  @include mq(mw6) {
    @include fontSize(25px);
  }

  @include mq(mlg) {
    font-size: 1.4rem;
  }
}

.sub-heading {
  @include fontSize(22px);
  color: $secondary_font_color;
  font-weight: 300;
  @include margin-top(20px);
  letter-spacing: 1px;
  line-height: 2.2rem;
  max-width: 35rem;
  @include mq(mw6) {
    @include fontSize(20px);
    max-width: 31rem;
  }
  @include mq(msm) {
    @include fontSize(18px);
    line-height: 1.9rem;
  }
}
