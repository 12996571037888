.bk-footer {
  background-color: $primary_color;
  @include cmmn_spacing;

  .footer-top {
    ul {
      display: flex;
      align-items: center;

      li {
        @include padding-left(34px);

        a {
          @include fontSize(16px);
          color: $secondary_font_color;
          font-weight: 400;

          &:hover{
            color: $primary_font_color;
          }
        }

        &:first-child {
          padding-left: 0;
        }
      }
    }

    address {
      @include fontSize(16px);
      color: $secondary_font_color;
      font-weight: 400;
      @include line-height(26px);
    }

    .stay-touch {
      text-align: center;

      form{
        margin-top: 2rem;
        display: flex;
        align-items: center;
        border: 1px solid $white;
        border-radius: 1.5rem;
        width: 100%;
        height: 4.5rem;
        padding: 1rem 2rem;

        input[type="email"]{
          font-size: 1.2rem;
          color: $secondary_font_color;
          font-weight: 300;
          background-color: transparent;
          border: none;
          outline: 0;
          width: calc(100% - 2rem);
        }

        input[type="email"]::placeholder{
          color: $secondary_font_color;
        }

        input[type="submit"]{
          width: 2rem;
          color: transparent;
          border: none;
          outline: 0;
          background: url("../images/right-arrow.svg") no-repeat center / 30px transparent;
        }
      }

      .stay-touch-title {
        @include fontSize(45px);
        color: $secondary_font_color;
        font-weight: 300;
      }

    }
    @include mq(mw6) {
      ul {
        li {
          @include padding-left(30px);
        }
      }

      .stay-touch {
        .stay-touch-title {
          @include fontSize(30px);
        }

        form{
          height: 4.2rem;
        }
      }
    }
    @include mq(mxl) {
      ul {
        li {
          @include padding-left(23px);

          a {
            @include fontSize(14px);
          }
        }
      }

      address {
        @include fontSize(14px);
        @include line-height(25px);
      }

      .stay-touch {
        .stay-touch-title {
          @include fontSize(25px);
        }

        form{
          height: 4rem;
          padding: 1rem 1.5rem;
          margin-top: 1.5rem;

          input[type="email"]{
            font-size: 1rem;
          }

          input[type="submit"]{
            background-size: 26px;
          }
        }
      }
    }
    @include mq(mlg) {
      div[class*="col"] {
        &:first-child {
          margin-top: 0;
        }
        @include margin-top(30px);
      }

      .stay-touch {
          text-align: left;

      }
    }

    @include mq(mmd) {
      .stay-touch {
        form{
          height: 3.5rem;
        }
      }
    }

    @include mq(msm) {
      ul {
        flex-direction: column;
        text-align: center;

        li {
          padding-left: 0;
          @include padding-top(15px);

          &:first-child {
            padding-top: 0;
          }

          a {
            @include fontSize(16px);
          }
        }
      }

      address {
        text-align: center;
      }

      .stay-touch{
        text-align: center;
      }
    }
  }

  .footer-bttom {
    margin-top: 5rem;
    padding-top: 3rem;
    border-top: 1px solid $secondary_font_color;

    p {
      color: $secondary_font_color;
      font-weight: 300;
      font-size: 1rem;

      img {
        height: 1.2rem;
      }
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      li {
        padding-right: 1.2rem;

        a:hover{
          svg{
            path{
              fill: $black;
            }
          }
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    @include mq(mxl) {
      margin-top: 4rem;
      padding-top: 2rem;
    }

    @include mq(mmd){
      p{
        text-align: center;
        margin-top: .8rem;
      }
      ul{
        justify-content: center;
      }
    }
  }
}
