.bk-contact-banner{
  height: 30rem;
  display: flex;
  align-items: center;
  position: relative;
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;

  @include mq(mw6){
    height: 22rem;
  }

  @include mq(mxl){
    height: 16rem;
  }

  @include mq(mmd){
    height: 15rem;

    .connect-us{
      top: 50%;
      transform: translateY(-50%);
      bottom: inherit;
    }
  }
}
