$primary_color: #c6010d !default;
$primary_font_color: #000000 !default;
$secondary_color: #0d1215 !default;
$secondary_font_color: #ffffff !default;
$link_color: #72a79e !default;

$border_radius: .75rem !default;
$border_color: #dadce0 !default;

$body_text: #000000 !default;
$body_light_text: #6e6e6e !default;
$light_text: #757575 !default;
$light_text-on_dark: #fefefe !default;
$headings_text: $body-text !default;

$white: #ffffff !default;
$black: #000000 !default;
$darkblack: #111111 !default;
$violet: #75718b !default;
$gray: #f3f3f3 !default;

$warning: #ffc107 !default;
$danger: #f44336 !default;
$success: #4caf50 !default;

$font_size: .9rem !default;
$font_heading_size: 2.2rem !default;
$font_weight: 400 !default;
$line_height: 1.5 !default;

$button_font_size: .8rem !default;
$button_font_weight: 500 !default;

$infinite: 99999999px;

$box_hadow: 0 .25rem .5rem rgba(0,0,0,0.15);
$box_shadow-_over: 0 .25rem 1rem rgba(0,0,0,0.35);

$primary_family: -apple-system,BlinkMacSystemFont,'Aeonik', sans-serif !default;
$secondary_family: -apple-system,BlinkMacSystemFont,'Roboto', sans-serif !default;
