::-webkit-input-placeholder {
    opacity: 1;
}

::-moz-placeholder {
    opacity: 1;
}

:-ms-input-placeholder {
    opacity: 1;
}

:-moz-placeholder {
    opacity: 1;
}
