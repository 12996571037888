.background-primary{
  background-color: $primary-color !important;
}
.background-secondary{
  background-color: $primary-color !important;
}
.background-gray{
  background-color: $gray !important;
}
.color-black{
  color: $black !important;
}
.color-white{
  color: $white !important;
}
