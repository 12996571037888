.bk-date-store {
  @include mq(xl) {
    .row {
      margin: 0px -30px;

      div[class*="col"]{
        padding: 0px 30px;
      }
    }
  }
  @include cmmn_spacing;
}
