.bk-map {
  background-color: $primary_color;
  
  .container{
      position: relative;
  }

  address {
    position: absolute;
    bottom: 4rem;
    left: 5rem;
    font-size: 1.3rem;
    color: $secondary_font_color;
    font-weight: 300;
  }

  figure{
    overflow: hidden;

    img{
      transform: scale(1.2);
      object-position: center;
      object-fit: cover;
      height: 30rem;
    }
  }

  @include mq(mxl) {
    figure {
      img {
        height: 27rem;
      }
    }
  }
  @include mq(mlg) {
    address {
      font-size: 1.1rem;
    }

    figure {
      img {
        height: 22rem;
        object-position: 70%;
      }
    }
  }
  @include mq(mmd) {
    figure {
      img {
        object-position: 75%;
      }
    }
  }

  @include mq(msm) {
    address {
      bottom: 2rem;
      left: 1rem;
    }
  }
}
