.bk-offering-services {
  padding: 6rem 0;

  .content-block {
    padding-left: 2rem;
  }

  @include mq(mw6){
    padding: 4rem 0rem;

    .content-block {
      padding-left: 1rem;
    }
  }

  @include mq(mmd){
    padding: 3rem 0rem;
    .content-block {
      padding-left: 0;
      margin-top: 2rem;
    }
  }
}

.services-slider-inner {
  background-color: $black;
  transform: all 0.2s ease;

  &:hover {
    background-color: $primary-color;
  }

  img {
    height: 34rem;
    object-fit: cover;
    object-position: center;
    opacity: 0.4;
  }

  .figure-caption {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    z-index: 2;

    h3 {
      color: $secondary-font-color;
      font-size: 1.6rem;
      position: relative;
      padding-left: 2.5rem;

      &:before{
        content: "";
        @include background("../images/tick.svg");
        background-size: 13px;
        height: 24px;
        width: 24px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        left: 0;
        top: 5px;
      }
    }
  }

  @include mq(mxl){
    .figure-caption {
      left: 2rem;

      h3{
        font-size: 1.3rem;

        &:before{
            top: 2px;
        }
      }
    }
  }

  @include mq(mmd){
    img{
      height: 27rem;
    }
  }

  @include mq(msm){
    img{
      height: 25rem;
    }
  }
}

.services-slider{
  .owl-nav{
    button{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      outline: 0;
    }
    .owl-prev{
      left: 1.2rem;
    }
    .owl-next{
      right: 1.2rem;
    }
  }
}
