.bk-team-work{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 8rem 0rem;

  @include mq(mw6){
    padding: 5rem 0rem;
  }
  @include mq(mxl){
    padding: 4rem 0rem;
  }
  @include mq(msm){
    padding: 3rem 0rem;
  }
}

.team-work-inner{
  max-width: 50rem;
  margin: auto;
  text-align: center;

  h2{
    font-size: 2rem;
    color: $secondary-font-color;
  }
  p{
    color: $secondary-font-color;
    margin-top: 2rem;
  }

  .btn{
    margin-top: 2rem;
  }

  @include mq(mlg){
    h2{
      font-size: 1.5rem;
      line-height: 2rem;
    }
    p{
      margin-top: 1.2rem;
    }
  }
}
