.bk-services {
  @include background("../images/service-bg.jpg");
  position: relative;

  .sec-heading,
  h2 {
    color: $secondary_font_color;
    opacity: 0;
    transition: all 0.2s ease;
  }

  .our-services-list {
    @include height(900px);
    background-color: rgba(0, 0, 0, .4);
    border-right: 1px solid $secondary_font_color;
    display: flex;
    flex-direction: column;
    padding: 6.25rem 3.125rem 5rem;
    transition: all 0.2s ease;

    &:last-child {
      border: none;
    }

    .our-services-cnt {
      margin-top: auto;

      h3 {
        color: $secondary_font_color;
      }

      p {
        color: $secondary_font_color;
        display: none;
        padding-top: .7rem;
      }

      a {
        @include fontSize(16px);
        color: $secondary_font_color;
        font-weight: 700;
        @include margin-top(25px);
        display: block;

        &:after {
          content: "";
          @include background("../images/right-arrow.svg");
          height: 14px;
          width: 30px;
          @include margin-left(25px);
          display: inline-block;
        }

        &:hover {
          color: $primary_color;

          &:after {
            filter: invert(72%) sepia(71%) saturate(7242%) hue-rotate(349deg) brightness(74%) contrast(115%);
          }
        }
      }
    }

    &:hover {
      background-color: rgba(198, 1, 13, .4);

      .sec-heading,
      h2 {
        opacity: 1;
      }

      @include mq(mw6) {
        .our-services-cnt {
          p {
            @include padding-top(20px);
          }
        }
      }
      @include mq(mlg) {
        background-color: transparent;
      }
    }
    @include mq(mw6) {
      padding: 4rem 1rem 3rem;
      @include height(600px);

      .sec-heading,
      h2 {
        @include fontSize(36px);
      }

      .our-services-cnt {
        h3 {
          @include fontSize(28px);
        }
      }
    }
    @include mq(mxl) {
      .sec-heading,
      h2 {
        @include fontSize(30px);
      }

      .our-services-cnt {
        h3 {
          @include fontSize(22px);
        }

        a {
          &:after {
            @include margin-left(20px);
          }
        }
      }
    }
    @include mq(mlg) {
      height: auto;
      background-color: transparent;
      border: none;
      padding: 0 15px;
      @include margin-top(50px);

      .sec-heading,
      h2 {
        display: none;
      }

      .our-services-cnt {
        margin-top: 0;
        height: 100%;
        position: relative;
        @include padding-bottom(40px);

        h3 {
          @include fontSize(25px);
        }

        p {
          display: block !important;
          @include padding-top(20px);
        }

        a {
          position: absolute;
          left: 0;
          bottom: 0;
        }
      }
    }
    @include mq(mmd) {
      .our-services-cnt {
        p {
          @include padding-top(15px);
        }

        a {
          @include margin-top(15px);
        }
      }
    }

    @include mq(msm){
      margin-top: 2.5rem;
    }
  }
  @include mq(mlg) {
    @include padding-bottom(50px);

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, .7);
    }

    .container-fluid {
      position: relative;
      z-index: 2;
    }
  }

  .owl-carousel.mob-services-slider{
    display: flex;

    @include mq(mmd){
      display: block;
    }
  }
}
