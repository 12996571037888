.bk-why-choose-us{
  .content-block {
    @include max-width(800px);
    text-align: center;
    margin: auto;

    p {
      text-align: justify;
      text-align-last: center;
    }
  }
  @include cmmn_spacing;
}

.content-block {
  p {
    @include margin-top(35px);
  }

  .btn {
    @include margin-top(40px);
  }

  @include mq(mw6) {
    p{
      margin-top: 1.5rem;
    }
    .btn{
      margin-top: 2rem;
    }
  }
  @include mq(mxl) {
    .btn {
      @include margin-top(30px);
    }
  }
  @include mq(mmd) {
    p {
      margin-top: 1rem;
    }
  }
}
