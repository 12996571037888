.bk-our-services{

  .right{
    background-color: $primary-color;
    height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h4{
      font-size: 1.55rem;
      color: $secondary-font-color;
      font-weight: 300;
      margin-bottom: 3rem;
      line-height: 2rem;
    }
  }

  @include mq(mw6){
    .right{
      h4{
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
    }
  }

  @include mq(mlg){
    .right{
      margin-top: 2rem;
      padding: 2rem;
      height: auto;
    }
  }

  @include mq(msm){
    .right{
      padding: 1.5rem 1.2rem;
    }
  }
}
