.link-txt {
  @include fontSize(16px);
  color: $primary_font_color;
  font-weight: 700;
  display: block;

  &:after {
    content: "";
    @include background("../images/red-right-arrow.svg");
    height: 14px;
    width: 30px;
    @include margin-left(20px);
    display: inline-block;
    vertical-align: -3px;
  }

  &:hover {
    color: $primary_color;
  }
}
