@mixin cmmn_spacing(){
    padding: 6.25rem 0rem;

    @include mq(mw6){
        padding: 3.75rem 0rem;
    }

     @include mq(mxl){
        padding: 3.125rem 0rem;
    }

    @include mq(mlg){
        padding: 2.5rem 0rem;
    }
}
