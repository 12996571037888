.IT-support-team {
  @include cmmn_spacing;

  .team-work-inner {
    max-width: 100%;
    padding: 0 4rem;
    text-align: left;

    @include mq(xl) {
      .row {
        margin: 0 -30px;

        div[class*="col"] {
          padding: 0 30px;
        }
      }
    }

    @include mq(mxl){
      padding: 0rem 2.5rem;
    }

    @include mq(msm){
      padding: 0rem 1rem;
    }
  }
}
