.bk-contact {
  @include cmmn_spacing;
  @include mq(w6) {
    .row {
      margin: 0 -30px;

      div[class*="col"] {
        padding: 0 30px;
      }
    }
  }
  @include mq(md) {
    div[class*="col"] {
      &:nth-child(-n + 2) {
        .input-feild {
          margin-top: 0;
        }
      }
    }
  }
  @include mq(mmd) {
    div[class*="col"] {
      &:first-child {
        .input-feild {
          margin-top: 0;
        }
      }
    }
  }
}

.input-feild {
  margin-top: 1.5rem;

  label {
    display: block;
    color: $primary_font_color;
    margin-bottom: 0.6rem;
  }

  input,
  textarea {
    height: 3rem;
    width: 100%;
    border: 1px solid #a1a1a1;
    padding: 0 1.2rem;
    color: #333333;
    outline: 0;
  }

  textarea {
    padding: 1.2rem;
    height: 10rem;
    resize: none;
  }

  input::placeholder,
  textarea::placeholder {
    color: rgba(51, 51, 51, 0.3);
  }

  .btn {
    font-family: $primary_family;
    color: $secondary_font_color;
    display: inline-block;
    width: auto;
    padding: 0.7rem 2.8rem 0.7rem 2rem;
    height: auto;
    outline: 0;
    border: none;
    background: url("../images/btn-icon.svg") no-repeat 81% 46%/18px $primary_color;

    &:hover {
      background-color: $secondary_color;
    }
  }
  @include mq(mlg) {
    input {
      height: 2.8rem;
    }
  }
}
