.bk-meet-us {
  .left {
    background-color: $primary_color;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 5rem;

    h2 {
      @include fontSize(40px);
      color: $secondary_font_color;
    }

    p {
      color: $secondary_font_color;
      @include margin-top(15px);
    }

    .btn {
      @include margin-top(40px);
      background-color: $secondary_font_color;
      color: $primary_color;

      &:after {
        background-image: url("../images/btn-icon2.svg");
      }

      &:hover {
        color: $secondary_font_color;
        background-color: $secondary_color;

        &:after {
          background-image: url("../images/btn-icon.svg");
        }
      }
    }
  }

  .right {
    figure {
      height: 100%;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  @include mq(mw6) {
    .left {
      padding: 1.875rem;

      h2 {
        @include fontSize(36px);
      }
    }
  }
  @include mq(mlg) {
    .left {
      h2 {
        @include fontSize(32px);
      }
    }
  }
  @include mq(mmd) {
    .right {
      figure {
        height: auto;

        img {
          height: auto;
        }
      }
    }
  }
  @include mq(msm) {
    .left {
      padding: 1.5625rem;

      h2 {
        @include fontSize(28px);
      }

      .btn {
        @include margin-top(25px);
      }
    }
  }
  @include cmmn_spacing;
  padding-top: 0 !important;
}
