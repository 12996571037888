@function calculateRem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: calculateRem($size);
}

@mixin line-height($size) {
  line-height: calculateRem($size);
}

@mixin padding-top($size){
    padding-top: calculateRem($size);
}

@mixin padding-right($size){
    padding-right: calculateRem($size);
}

@mixin padding-bottom($size){
    padding-bottom: calculateRem($size);
}

@mixin padding-left($size){
    padding-left: calculateRem($size);
}

@mixin margin-top($size){
    margin-top: calculateRem($size);
}

@mixin margin-right($size){
    margin-right: calculateRem($size);
}

@mixin margin-bottom($size){
    margin-bottom: calculateRem($size);
}

@mixin margin-left($size){
    margin-left: calculateRem($size);
}

@mixin left($size){
    left: calculateRem($size);
}
@mixin right($size){
    right: calculateRem($size);
}
@mixin top($size){
    top: calculateRem($size);
}

@mixin bottom($size){
    bottom: calculateRem($size);
}

@mixin border-radius($size){
    border-radius: calculateRem($size);
}

@mixin height($size){
    height: calculateRem($size);
}

@mixin width($size){
    width: calculateRem($size);
}

@mixin max-width($size){
    max-width: calculateRem($size);
}
