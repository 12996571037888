
.bk-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: $white;
  transition: all 0.2s ease;

  .navbar-brand {
    padding: 0;
    transition: all 0.2 ease;
  }

  .navbar {
    padding: 0;
  }

  .navbar-nav {
    ul {
      display: flex;
      align-items: center;
      margin-left: auto;

      li {
        padding: 2rem 1.875rem;
        position: relative;
        transition: all .2s ease;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          @include fontSize(16px);
          color: transparent;
          text-transform: capitalize;
          display: inline-block;
          text-align: center;
          position: relative;
          padding: 0px 4px;

          &:after{
            content: attr(title);
              position: absolute;
              color: $primary_font_color;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
              font-weight: 400;
              transition: all .2s ease;
          }

          @includ mq(mxl) {
            @include fontSize(14px);
          }
        }

        &:hover,
        &.active{
            a{
              &:after{
                font-weight: 700;
                color: $primary_color;
              }
            }
        }

        @include mq(lg){
          &:hover{
            .sub-menu{
              display: block;
            }
          }
        }

        .sub-menu{
          position: absolute;
          left: 0;
          top: 100%;
          flex-wrap: wrap;
          background-color: $primary_color;
          padding: 1.5rem 0rem;
          width: 18rem;
          display: none;
          transition: all .2s ease;
          animation: fadein .6s ease;

          li{
            width: 100%;
            padding: 0;

            &:hover{
              a{
                font-weight: 400;
                background-color: $white;
                color: $primary_color;
              }
            }

            a{
              text-align: left;
              padding: .4rem 2rem;
              width: 100%;
              font-weight: 400;
              color: $white;
            }

            &:first-child{
              padding-top: 0rem;
            }
          }
        }
      }
    }

    @keyframes fadein {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @include mq(mw6) {
      ul li {
        padding: 2rem  1.2rem;

        .sub-menu{
          width: 15rem;

          li{
            a{
              padding: .4rem 1.5rem;
            }
          }
        }
      }
    }
    @include mq(mxl) {
      ul li {
        padding: 1.5rem 1rem;

        &:hover{
          a{
            font-weight: 400;
          }
        }
      }
    }
    @include mq(mlg) {
      align-items: flex-start;

      ul {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: inherit;
        width: 100%;

        li{
          width: 100%;

          a{
            &:after{
              color: $white;
            }
          }

          &:hover,&.active{
            a:after{
              color: $white;
            }
          }
        }

        li.menu-item-has-children{
          position: relative;

          &:after{
            content: "";
            position: absolute;;
            right: 0;
            top: 24px;
            width: 16px;
            height: 8px;
            @include background("../images/dropdown-arrow.svg");
            transition: all .2s ease;
          }

          &.active{
            &:after{
              transform: rotate(180deg);
            }
          }

          .sub-menu{
            position: relative;
            top: inherit;
            left: inherit;
            padding: .6rem 0rem;
            transition: none;

            li a{
                padding: .4rem 0rem;
                color: $white;

                &:hover{
                  background-color: transparent;
                }
            }
          }
        }
      }

      ul li {
        padding: 15px 0;
      }
    }
  }

  @include mq(mxl) {
    .navbar-brand {
      @include width(180px);
    }
  }

  @include mq(mlg) {
    padding: 0.5rem 0;

    .navbar-brand {
      order: 1;
    }

    .navbar-collapse {
      position: fixed;
      top: 82px;
      left: 0;
      padding: 1.875rem;
      width: 18.75rem;
      height: 100%;
      overflow: scroll;
      background-color: $primary_color;
      overflow: -moz-scrollbars-none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;
    }

    .navbar-collapse::-webkit-scrollbar {
      width: 0 !important;
    }

    .navbar-collapse.collapsing {
      left: -18.75rem;
      transition: height 0s ease;
    }

    .navbar-collapse.show {
      left: 0;
      transition: left 0.3s linear;
    }

    .navbar-toggler.collapsed ~ .navbar-collapse {
      transition: left 0.3s linear;
    }

    .navbar-toggler {
      padding: 0;
      outline: 0;
      border: none;
      order: 3;
      height: 3rem;
      width: 3rem;
    }

    .navbar-toggler-icon {
      background-image: none !important;
      position: relative;
      height: 3px;
      width: 30px;
      background-color: transparent;
      display: block;
      margin-left: auto;

      &:after,
      &:before {
        content: "";
        position: absolute;
        background-color: $primary_color;
        height: 3px;
        width: 30px;
        left: 0;
        transition: all 0.2s ease-out;
      }

      &:before {
        transform: rotate(45deg);
        top: 0;
      }

      &:after {
        transform: rotate(-45deg);
        top: 0;
      }
    }

    .navbar-toggler.collapsed {
      .navbar-toggler-icon {
        background-color: $primary_color;

        &:before {
          transform: rotate(0deg);
          top: -10px !important;
        }

        &:after {
          transform: rotate(0deg);
          bottom: -10px !important;
          top: inherit;
        }
      }
    }
  }

  .get-start {
    @include padding-left(60px);

    @include mq(mw6){
      padding-left: 2.5rem;
    }

    @include mq(mlg){
      order: 2;
      margin-left: auto;
      padding-right: 2rem;
      padding-left: 0;
    }

    @include mq(msm){
      padding-left: 0;
      @include margin-top(20px);
      margin-left: 0;

      .btn {
        background-color: $white;
        color: $primary_color;
        box-shadow: none;
        outline: 0;

        &:after {
          background-image: url("../images/btn-icon2.png");
        }
      }
    }
  }
}

.bk-header.fixedtop {
  box-shadow: $box_hadow;

  .navbar-brand {
    transform: scale(.8);
    transform-origin: center;
  }

.navbar-nav{
    ul li{
        padding: 1.5rem 1.875rem;

        &:first-child{
          padding-left: 0;
        }

        &:last-child{
          padding-right: 0;
        }
    }
  }

  @include mq(mw6) {
    .navbar-nav{
      ul li{
          padding: 1.6rem 1.2rem;
      }
    }
  }

  @include mq(mxl) {
    .navbar-brand {
      transform: scale(.9);
    }

    .get-start {
      .btn {
        padding: 0.5rem 1.5rem;
      }
    }

    .navbar-nav{
      ul li{
          padding: 1.5rem 1rem;
      }
    }
  }
  @include mq(mlg) {
    padding: 0.5rem 0;

    .navbar-brand {
      transform: scale(1);
    }

    .navbar-nav{
      ul li{
          padding: 15px 0px;
      }
    }

    .get-start {
      .btn {
        padding: 0.625rem 1.5rem;
      }
    }
  }
}

.menu-overlay{
  display: none;
}
.menu-opened .menu-overlay{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 120vh;
  background-color: rgba(0,0,0,.7);
  z-index: 99;
  display: block;
}

body.modal-open {
    padding-right: 0px !important;
}

.get-start-popup{
  .modal-dialog{
    max-width: 35rem;

    .modal-content{
      padding: 3rem;
      border: none;

      .modal-header{
        padding: 0;
        border: none;
        position: relative;

        .modal-title{
          font-size: 2rem;
          font-weight: 500;
        }

        .close{
          position: absolute;
          right: 12px;
          top: 27px;
          outline: 0;
          border: none;
          opacity: 1;
          padding: 0;

          span{
            width: 35px;
            height: 35px;
            border: 1px solid $black;
            border-radius: 50%;
            display: inline-block;
            position: relative;

            &:after,&:before{
              content: "";
              width: 20px;
              height: 1px;
              background-color: $black;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;;
            }

            &:after{
              transform: rotate(45deg);
            }

            &:before{
              transform: rotate(-45deg);
            }
          }
        }
      }

      .modal-body{
        padding: 0;
      }
    }
  }

  .input-feild{
    .btn{
      margin-top: .5rem;
    }
  }

  @include mq(mmd){
    .modal-dialog{
      max-width: 28rem;

      .modal-content{
        padding: 2rem;
      }
    }
  }

  @include mq(msm){
    .modal-dialog {
      .modal-content {
          padding: 2rem 1.5rem;

        .modal-header{
          .modal-title{
            font-size: 1.5rem;
          }
          .close{
              top: 14px;
          }
        }
      }
    }
  }
}
