.btn {
  @include fontSize(15px);
  color: $secondary_font_color;
  background-color: $primary_color;
  padding: 0.7rem 2.1875rem;
  @include border-radius(20px);
  letter-spacing: 1px;

  &:after {
    content: "";
    @include background("../images/btn-icon.svg");
    width: 21px;
    height: 18px;
    display: inline-block;
  }

  &:hover {
    color: $secondary_font_color;
    background-color: $secondary_color;
  }
  @include mq(mxl) {
    padding: 0.625rem 1.5rem;
  }
}

.white-btn{
  background-color: $white;
  color: $primary_color;

  &:after{
    background-image: url("../images/btn-icon2.svg");
  }

  &:hover{
    color: $white;
    background-color: $secondary_color;

    &:after{
      background-image: url("../images/btn-icon.svg");
    }
  }
}
