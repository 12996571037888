.bk-banner {
  height: 100%;
  @include background("../images/banner-bg.jpg");
  padding: 6.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .down-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    animation: bounce 2s infinite;
    @include bottom(20px);
  }

  .banner-inner {
    @include max-width(800px);

    .btn {
      @include margin-top(40px);
    }
  }
  @include mq(mw6) {
    .banner-inner {
      .btn{
        margin-top: 2rem;
      }
    }
  }
  @include mq(mlg) {
    padding: 3.125rem 0;
  }
}
@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

.connect-us {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:after {
    content: "";
    width: 40px;
    height: 1px;
    background-color: $primary_color;
    bottom: 0;
    top: 0;
    margin: auto;
    right: 8px;
    position: absolute;
    transition: all 0.3s ease;
    transition-delay: 0.2s;
  }

  ul {
    li {
      &:last-child {
        a {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    @include height(60px);
    @include width(320px);
    background-color: $white;
    padding: 0 10px;
    @include fontSize(18px);
    color: $primary_font_color;
    font-weight: 500;
    position: relative;
    @include right(-260px);
    transition: all 0.3s ease;

    figure {
      @include height(50px);
      @include width(50px);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
    }

    span {
      padding-left: 5px;
      width: calc(100% - 50px);
    }

    &:hover {
      right: 0;
    }
  }
  @include mq(mmd) {
    a {
      @include fontSize(16px);
      @include width(275px);
      @include right(-212px);
    }
  }
  @include mq(mmd) {
    top: inherit;
    transform: inherit;
    @include bottom(30px);
  }
}
