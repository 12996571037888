
.bk-about-banner{  
  min-height: auto !important;
  padding: 7rem 0rem;
  height: auto;

  @include mq(mxl){
    padding: 5rem 0rem;
  }

  @include mq(mlg){
    padding: 3rem 0rem;
  }

  @include mq(mmd){
    padding: 3rem 0rem 5rem;
  }
}
