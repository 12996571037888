.bk-testimonial {
  @include background("../images/testimonial-bg.jpg");
  padding: 10rem 0;

  .testimonial-slider {
    @include max-width(650px);

    p {
      color: $secondary_font_color;
      @include fontSize(20px);
      @include line-height(34px);
    }

    .testimonial-footer {
      @include fontSize(25px);
      font-weight: 500;
      color: $secondary_font_color;
      @include margin-top(25px);
    }

    .testimonial-cnt {
      @include padding-top(100px);

      &:before {
        content: "";
        @include background("../images/quotes.png");
        @include width(50px);
        @include height(41px);
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  @include mq(mw6) {
    padding: 6rem 0;

    .testimonial-slider {
      .testimonial-footer {
        @include fontSize(22px);
        @include margin-top(18px);
      }

      .testimonial-cnt {
        @include padding-top(75px);
      }

      p {
        @include fontSize(18px);
        @include line-height(30px);
      }
    }
  }
  @include mq(mxl) {
    padding: 5rem 0;

    .testimonial-slider {
      p{
        font-size: 1rem;
      }
    }
  }
  @include mq(mlg) {
    padding: 3rem 0;

    .testimonial-slider {
      .testimonial-cnt {
        &:before {
          @include width(40px);
          @include height(32px);
        }
      }

      .testimonial-footer {
        @include fontSize(20px);
      }
    }
  }
  @include mq(msm) {
    .testimonial-slider {
      .testimonial-cnt {
        @include padding-top(55px);

        p {
          @include fontSize(16px);
        }
      }
    }
  }
}
