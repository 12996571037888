.bk-logos {
  @include cmmn_spacing;

  .logo-slider {
    .owl-stage {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    figure {
      text-align: center;
    }

    img {
      display: inline-block;
      width: auto;
      max-width: 150px;
      max-height: 120px;
      opacity: 0.4;
      transition: all.2s ease;

      &:hover {
        opacity: 1;
      }
    }
    @include mq(mw6) {
      img {
        max-width: 120px;
        max-height: 80px;
      }
    }
  }
}
