.bk-list {
  ul {
    li {
      color: $secondary_font_color;
      font-weight: 300;
      margin-top: 1.6rem;
      position: relative;
      padding-left: 2.5rem;

      &:first-child{
        margin-top: 0;
      }

      &:before {
        content: "";
        @include background("../images/tick.svg");
        background-size: 10px;
        width: 22px;
        height: 22px;
        position: absolute;
        left: 0;
        top: 5px;
        border: 1px solid $white;
        border-radius: 50%;
      }

      @include mq(mmd){
        margin-top: 1.2rem;
      }
    }
  }
}
