body,
html {
  width: 100%;
  overflow-x: hidden;
}

body {
  font-family: $primary_family;
  @include fontSize(18px);
  color: $primary_font_color;
  @include line-height(30px);
  font-weight: 400;
  @include mq(mw6) {
    @include fontSize(16px);
    @include line-height(28px);
  }
}
.menu-opened{
  height: 100vh;
  overflow: hidden;
}

.show-xs{
  display: none;

  @include mq(msm){
    display: block;
  }
}

.hide-xs{
  display: block;

  @include mq(msm){
    display: none;
  }
}
